import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="bg-dark text-white big-padding pb-0">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-4">
              <div className="aboutcol bg-gray fs-7 bg-dark shadow-lg p-5">
                <p className="mb-2">
                  Avatara TechNoBiz is a leading digital agency, dedicated to
                  delivering high-quality, cost-effective, and reliable web and
                  e-commerce solutions.
                </p>
                <p className="mb-2">
                  We specialize in providing innovative, results-driven services
                  that are tailored to meet the unique needs of our global
                  clientele. With a strong focus on delivering projects on time,
                  we help businesses achieve their digital goals by combining
                  cutting-edge technology with creative excellence.
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="row">
                    <div className="col-md-10">
                      <h5 className="text-light fw-bolder  fs-3">
                        Surat, Gujarat{" "}
                        <i className="bi fs-1 bi-geo-alt text-primary"></i>
                      </h5>

                      <p className="fs-6">
                        Office No 14, 5th floor, Madhuram Arcade-2, Near
                        Madhuram Circle Dindoli, Surat, 394210
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-10">
                      <p className="fs-6 fw-500">Call us </p>
                      <h5 className="text-light fw-bolder fs-3 phone-icon ">
                        <i className="bi fs-1 bi-telephone-inbound text-primary me-2"></i>
                        +91 9558476369
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pt-5 footer">
                  <h4 className="fs-5 fw-bolder text-white">Useful Links</h4>
                  <ul className="row">
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Home
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        What we do
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Services
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        About Us
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Career
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Contact Us
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Features
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Refund Policy
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Gallery
                      </a>
                    </li>
                    <li className="float-start col-md-6 p-2">
                      <a className=" fw-bolder" href="#">
                        Albums
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 pt-5">
                  <h4 className="fs-5 fw-bolder text-warning">
                    Avatara TechNoBiz
                  </h4>
                  <p className="mb-5">
                    {" "}
                    Avatara TechnoBiz is a Surat-based Digital Agency passing
                    high quality, cost-productive, reliable result-oriented web
                    and e-commerce clarifications on time for a global
                    clientele.
                  </p>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control mb-0"
                      placeholder="Recipient's username"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text h-100"
                        id="basic-addon2"
                      >
                        <i className="bi fw-3 fs-1 text-dark bi-send"></i>
                      </span>
                    </div>
                  </div>
                  <ul className="text-white pt-1 float-end">
                    <li className="float-start fs-6 p-3 py-2">
                      <i className="bi bi-facebook"></i>
                    </li>
                    <li className="float-start fs-6 p-3 py-2">
                      <i className="bi bi-twitter"></i>
                    </li>
                    <li className="float-start fs-6 p-3 py-2">
                      <i className="bi bi-instagram"></i>
                    </li>
                    <li className="float-start fs-6 p-3 py-2">
                      <i className="bi bi-linkedin"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy">
        <div className="container">
          <a>Copyright © 2024 Avatara TechnoBiz. All rights reserved.</a>
          <span>
            <a href="#">
              <i className="fab fa-github"></i>
            </a>
            <a href="#">
              <i className="fab fa-google-plus-g"></i>
            </a>
            <a href="https://in.pinterest.com/prabnr/pins/">
              <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="https://twitter.com/prabinraja89">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.facebook.com/freewebtemplatesbysmarteye">
              <i className="fab fa-facebook-f"></i>
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
