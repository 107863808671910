import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Career = () => {
  const jobOpenings = [
    {
      title: "Python Developer",
      description:
        "We are looking for a Python Developer with experience in building high-performing, scalable, enterprise-grade applications.",
      requirements: [
        "Experience - 0 to 3 Year " ,
        "Strong understanding of Python and its libraries",
        "Experience with Django or Flask",
        "Knowledge of front-end technologies like JavaScript, HTML5, and CSS3",
        "Familiarity with databases (MySQL, PostgreSQL, MongoDB)",
        "Excellent problem-solving skills",
      ],
      skills: [
        "Python",
        "Django",
        "Flask",
        "JavaScript",
        "HTML5",
        "CSS3",
        "MySQL",
        "PostgreSQL",
        "MongoDB",
      ],
      jobType: "Full-time",
    },
    {
      title: "React.js Developer",
      description:
        "We are looking for a skilled React.js Developer to join our front-end development team.",
      requirements: [
        "Experience - 0 to 3 Year " ,
        "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
        "Thorough understanding of React.js and its core principles",
        "Experience with popular React.js workflows (such as Flux or Redux)",
        "Familiarity with RESTful APIs",
        "Knowledge of modern authorization mechanisms, such as JSON Web Token",
      ],
      skills: [
        "React.js",
        "JavaScript",
        "Redux",
        "RESTful APIs",
        "JSON Web Token",
      ],
      jobType: "Full-time",
    },
    {
      title: "Node.js Developer",
      description:
        "We are seeking a Node.js Developer to join our back-end development team.",
      requirements: [
        "Experience - 0 to 3 Year " ,
        "Strong proficiency with JavaScript or TypeScript",
        "Knowledge of Node.js and frameworks such as Express",
        "Experience with SQL or NoSQL databases",
        "Understanding of server-side templating languages",
        "Familiarity with front-end technologies, such as HTML5 and CSS3",
      ],
      skills: [
        "Node.js",
        "Express",
        "JavaScript",
        "TypeScript",
        "SQL",
        "NoSQL",
        "HTML5",
        "CSS3",
      ],
      jobType: "Full-time",
    },
    {
      title: "React Native Developer",
      description:
        "We are looking for a React Native Developer to join our mobile app development team.",
      requirements: [
        "Experience - 0 to 3 Year " ,
        "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
        "Thorough understanding of React Native and its core principles",
        "Experience with popular React Native workflows (such as Redux)",
        "Familiarity with RESTful APIs",
        "Experience with common mobile development tools and libraries",
      ],
      skills: [
        "React Native",
        "JavaScript",
        "Redux",
        "RESTful APIs",
        "Mobile Development",
      ],
      jobType: "Full-time",
    },
    {
      title: "Web Designer",
      description:
        "We are looking for a creative Web Designer to create beautiful, user-friendly websites that drive business results. You should have a keen eye for design and be able to translate business requirements into visually appealing, functional web pages.",
      requirements: [
        "Experience - 0 to 3 Year " ,
        "Proven experience in web design (portfolio required)",
        "Proficient in HTML5, CSS3, and JavaScript",
        "Strong understanding of responsive design principles",
        "Familiarity with design tools like Adobe XD, Figma, Sketch, or Photoshop",
        "Experience with UI/UX design principles",
      ],
      skills: [
        "HTML5",
        "CSS3",
        "JavaScript",
        "UI/UX Design",
        "Adobe XD",
        "Figma",
        "Responsive Design",
      ],
      jobType: "Full-time",
    },
    {
      title: "Business Development Executive (BDE)",
      description:
        "We are looking for an experienced and driven Business Development Executive to help us expand our customer base, build relationships, and achieve sales targets. The ideal candidate will have strong communication skills and a proven track record of generating leads and closing deals.",
      requirements: [
        "Experience - 0 to 3 Year " ,
        "Proven experience in business development or sales",
        "Strong negotiation and communication skills",
        "Ability to identify new business opportunities and partnerships",
        "Familiarity with CRM software and lead generation tools",
        "Self-motivated with a results-driven approach",
      ],
      skills: [
        "Sales",
        "Negotiation",
        "Lead Generation",
        "CRM Software",
        "Relationship Building",
      ],
      jobType: "Full-time",
    },
  ];

  return (
    <div>
      <div className="page-nav no-margin row">
        <Container>
          <Row>
            <h2 className="text-start mt-5">Career</h2>
            <ul>
              <li>
                <a href="#">
                  <i className="bi bi-house-door"></i> <Link to="/">Home</Link>
                </a>
              </li>
              <li>
                <i className="bi bi-chevron-double-right pe-2"></i> Career
              </li>
            </ul>
          </Row>
        </Container>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <h2 className="text-center mb-5">Career Opportunities</h2>
          <div className="row">
            {jobOpenings.map((job, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div className="job-card p-4 bg-white shadow-sm rounded">
                  <h3 className="fs-5 fw-bold text-primary">{job.title}</h3>
                  <p className="text-muted">{job.description}</p>

                  <h4 className="fs-6 fw-bold mt-3">Requirements:</h4>
                  <ul className="list-unstyled">
                    {job.requirements.map((req, idx) => (
                      <li key={idx} className="mb-2">
                        <i className="fas fa-check-circle text-success me-2"></i>
                        {req}
                      </li>
                    ))}
                  </ul>

                  <Link to="/form">
                    <button className="btn btn-primary mt-3 w-100">
                      Apply Now
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
