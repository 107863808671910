import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

const ServicePage = () => {

  const service = [{ image: "/assets/images/serviceimg/Website-Design.png", title: "Website Desgin" },
  { image: "/assets/images/serviceimg/Responsive-Mobile-Websites.png", title: "Responsive+ Mobile websites" },
  { image: "/assets/images/serviceimg/Graphic-Design.png", title: "Graphics Desgin" },
  { image: "/assets/images/serviceimg/Logo-Design-1.png", title: "Logo Desgin" },
  { image: "/assets/images/serviceimg/Custom-Web-Design.png", title: "Custom Web Desgin" },

  ]

  const webDevelopment = [
    { image: "/assets/images/servicesImg/python-logo.png", title: "Python" },
    { image: "/assets/images/serviceimg/React-1.png", title: "React" },
    { image: "/assets/images/servicesImg/node.png", title: "Node" },
    { image: "/assets/images/servicesImg/nextjs-icon.png", title: "Next" },
    { image: "/assets/images/servicesImg/mern-stack.png", title: "MERN" },
    { image: "/assets/images/serviceimg/GSL-angular.png", title: "Angular" },
    { image: "/assets/images/serviceimg/react-native-logo.png", title: "React Native" },
    { image: "/assets/images/serviceimg/flutter (1).png", title: "Flutter" },
    { image: "/assets/images/serviceimg/php-1.png", title: "PHP Development" },
    { image: "/assets/images/serviceimg/GSL-wordpress.png", title: "WordPress Development" }

  ]

  return (
    <div className="bg-white">
      <div className="page-nav  no-margin row">
        <Container>
          <Row>
            <h2 className="text-start mt-5 ">Our Services</h2>
            <ul>
              <li><a href="#"><i className="bi bi-house-door"></i>  <Link to="/">Home</Link></a></li>
              <li><i className="bi bi-chevron-double-right pe-2"></i> Services</li>
            </ul>
          </Row>
        </Container>
      </div>

      <div className="container-fluid products bg-gray big-padding pt-3">
        <Container className="container-xl mb-5">
          <h2 className='text-center text-warning fw-bold mt-3 '> Front End</h2>
          <Row className="mt-5  ">
            {
              service.map(({ image, title }, index) => (
                <Col xs="12" sm="6" md="4" lg="2" className="mb-4 d-flex justify-content-center mx-auto">
                  <div className="cover text-center  border p-5 mt-3" key={index} style={{ height: "200px", width: "200px" }}>
                    <img className="" src={image} alt={title} />
                    <h2 className="fs-5 fw-bolder mt-4 mb-3 text-center text-dark">{title}</h2>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
        <Container className="container-xl mt-3">
          <h2 className='text-center text-warning fw-bold mt-4'>Web & App Development</h2>
          <Row className="mt-5 justify-content-center">
            {webDevelopment.map(({ image, title }, index) => (
              <Col xs="12" sm="6" md="4" lg="2" className="mb-4 d-flex justify-content-center mx-1" key={index}>
                <div className="cover text-center border p-4" style={{ height: "200px", width: "200px" }}>
                  <img className="w-50" src={image} alt={title} />
                  <h2 className="fs-5 fw-bolder mt-4 mb-3 text-center text-dark">{title}</h2>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ServicePage;
