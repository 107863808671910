import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeadAndScripts from './HeadAndScripts';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import ContactPage from './components/ContactPage';
import ServicePage from './components/ServicePage';
import Career from './components/Career';
import WhatWeDoPage from './components/WhatWeDoPage';
import "./App.css"
import Loader from './components/Loader';
import JobApplicationForm from './components/JobApplicationForm';
const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const timer = setTimeout(() => {
          setLoading(false);
      }, 2000); 

      return () => clearTimeout(timer);
  }, []);
  return (
    <Router>
      <HeadAndScripts />
       {loading ? (
        <Loader /> 
      ) : (
        <>
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/services" element={<ServicePage />} />
              <Route path="/career" element={<Career />} />
              <Route path="/whatwedo" element={<WhatWeDoPage />} />
               <Route path="/form" element={<JobApplicationForm />} />
              {/* Add more routes here */}
            </Routes>
          </main>
          <Footer />
        </>
      )}
    </Router>
  );
};

export default App;
