import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const teamMembers = [
  {
    name: "Williams",
    role: "UI/UX Developer",
    photo: "assets/images/team/3.jpg",
  },
  {
    name: "Jane Doe",
    role: "Front-End Developer",
    photo: "assets/images/team/2.jpg",
  },
  {
    name: "John Smith",
    role: "Back-End Developer",
    photo: "assets/images/team/4.jpg",
  },
  {
    name: "Emily Johnson",
    role: "Graphic Designer",
    photo: "assets/images/team/1.jpg",
  },
  // Add more team members here
];

const AboutUs = () => {
  return (
    <div>
      {/* Page Title */}
      <div className="page-nav no-margin row">
        <div className="container">
          <div className="row">
            <h2 className="text-start mt-5">About Us</h2>
            <ul>
              <li>
                <a href="#">
                  <i className="bi bi-house-door"></i> <Link to="/">Home</Link>
                </a>
              </li>
              <li>
                <i className="bi bi-chevron-double-right pe-2"></i> About Us
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <section className="b-about-section" data-testid="b-about-section">
        <div className="container-fluid mt-5 mb-5 About">
          <div className="container">
            <h4 className="text-center text-warning mb-3 fw-bold fs-2">
              About Avatara TechnoBiz
            </h4>
            <p
              className="w-100 w-md-75 w-lg-60 mx-auto text-center mb-3 fw-bold"
              style={{ color: "#555" }}
            >
              We started in 2024 with a vision to positively impact businesses.
              We take pride in delivering innovative solutions and services with
              a team of 80+ professionals to help our clients achieve their
              goals. We work with a range of industries, including Education,
              Healthcare, Finance, Legal, E-commerce, Social Networking,
              Automobile, and Fitness. Our solutions are tailored to our
              clients' specific needs, and we are committed to quality,
              reliability, and innovation.
            </p>
          </div>

          {/* Mission, Vision, and Values Section */}
          <div className="row justify-content-center px-3">
            {/* Our Mission */}
            <div
              className="col-lg-3 mt-3 mx-2 info-box"
              style={{
                backgroundColor: "#f9fafb",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              }}
            >
              <div className="b-about-item text-center p-4">
                <div className="b-about-icon mb-3">
                  <img
                    alt="Our-mission-icon"
                    loading="lazy"
                    width="80"
                    height="80"
                    decoding="async"
                    src="assets/images/about/our-mission.svg"
                  />
                </div>
                <h2 className="fw-bold" style={{ color: "#0066cc" }}>
                  Our Mission
                </h2>
                <p className="text-muted">
                  At Avatara TechnoBiz, our mission is to empower businesses by
                  providing exceptional IT services and consulting solutions. We
                  leverage cutting-edge technology and industry best practices
                  to deliver innovative solutions that enable our clients to
                  achieve their strategic objectives.
                </p>
              </div>
            </div>

            {/* Our Vision */}
            <div
              className="col-lg-3 mt-3 mx-2 info-box"
              style={{
                backgroundColor: "#f9fafb",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              }}
            >
              <div className="b-about-item text-center p-4">
                <div className="b-about-icon mb-3">
                  <img
                    alt="Our-vision-icon"
                    loading="lazy"
                    width="80"
                    height="80"
                    decoding="async"
                    src="assets/images/about/our-vision.svg"
                  />
                </div>
                <h2 className="fw-bold" style={{ color: "#0066cc" }}>
                  Our Vision
                </h2>
                <p className="text-muted">
                  Our vision is to be a globally recognized provider of IT
                  services, known for expertise, integrity, and unwavering
                  commitment to deliver exceptional value to our clients. We aim
                  to optimize operations, enhance productivity, and drive
                  sustainable growth for businesses worldwide.
                </p>
              </div>
            </div>

            {/* Our Values */}
            <div
              className="col-lg-3 mt-3 mx-2 mb-2 info-box"
              style={{
                backgroundColor: "#f9fafb",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              }}
            >
              <div className="b-about-item text-center p-4">
                <div className="b-about-icon mb-3">
                  <img
                    alt="Our-value-icon"
                    loading="lazy"
                    width="80"
                    height="80"
                    decoding="async"
                    src="assets/images/about/our-value.svg"
                  />
                </div>
                <h2 className="fw-bold" style={{ color: "#0066cc" }}>
                  Our Values
                </h2>
                <p className="text-muted">
                  We believe in achieving our goals through integrity,
                  accountability, trust-building, and transparency in a
                  productive environment. Our values guide us to always deliver
                  excellence and foster long-term relationships with our clients
                  and partners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about-us big-padding container-fluid">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6 p-4">
              <img src="assets/images/about.png" alt="" />
            </div>
            <div className="col-md-6 align-self-center">
              <h2 className="fs-13 fw-bold">Modern & luxury living complexes</h2>
              <h4 className="text-primary my-4 fs-5 fw-bold">Duis irure dolor lipsum is simply free text available.</h4>
              <p className="fs-6 mb-3">Interior of volumes, space, air, proportion, with certain light and mood...</p>
              <p className="fs-6">Thinking of selling or letting your property...</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Our Team Section */}
      <section className="team bg-gray big-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>Meet our team</h2>
                <p className="text-dark">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit...
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {teamMembers.map((member, index) => (
              <div className="col-lg-3 col-md-4 col-sm-12 col-6" key={index}>
                <div className="team-main-box bg-white shadow-md text-center mt-2">
                  <img src={member.photo} alt={member.name} />
                  <div className="team-content-box">
                    <ul className="d-flex justify-content-center mt-2 px-1 ">
                      <li className="px-1">
                        <i className="fab fa-facebook-f"></i>
                      </li>
                      <li className="px-1">
                        <i className="fab fa-twitter"></i>
                      </li>
                      <li className="px-1">
                        <i className="fab fa-instagram"></i>
                      </li>
                    </ul>
                    <h3 className="fw-bolder mt-3 fs-4 mb-0">{member.name}</h3>
                    <p className="pb-3">{member.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
